import type { User, Customer, Organization } from '@dreamshaper/ds-interface'
import * as Sentry from '@sentry/vue'
import type { Token } from '@/types/Token'

export const useUser = () => {
  const auth = useCookie<Token | null>('auth')
  const rememberUserAuth = useCookie<boolean>('rememberUserAuth')
  const user = useCookie<User | null>('user')
  const org = useState<Organization | null>('org', () => null)
  const customer = useState<Customer | null>('customer', () => null)

  watch(user, (newUser) => {
    if (typeof window === 'undefined') return

    Sentry.setUser(newUser
      ? {
          id: newUser.id,
          email: newUser.email,
        }
      : null)
  })

  watch(org, (newOrg) => {
    if (typeof window === 'undefined') return

    Sentry.setTag('org', newOrg?.subdomain || null)
  }, {
    deep: true,
  })

  return {
    auth,
    rememberUserAuth,
    user,
    org,
    customer,
  }
}
